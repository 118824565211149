/* eslint-disable react/prop-types */
import React from 'react'

import PortableText from '../PortableText'

export default function MultiColumnSection({ section }) {
  const { columns } = section

  const widthSwitch = width => {
    switch (width) {
      case '1/12':
        return 'w-1/12'
      case '2/12':
        return 'w-2/12'
      case '3/12':
        return 'w-3/12'
      case '4/12':
        return 'w-4/12'
      case '5/12':
        return 'w-5/12'
      case '6/12':
        return 'w-6/12'
      case '7/12':
        return 'w-7/12'
      case '8/12':
        return 'w-8/12'
      case '9/12':
        return 'w-9/12'
      case '10/12':
        return 'w-10/12'
      case '11/12':
        return 'w-11/12'
      case '12/12':
        return 'w-12/12'
      default:
        return 'w-full'
    }
  }

  return (
    <div className="px-1 3xl:py-10 md:container md:mx-auto">
      <div className="flex flex-col md:flex-row flex-wrap">
        {columns &&
          columns.map((column, index) => (
            <>
              <div
                key={index}
                className={`flex-grow w-full md:${widthSwitch(column.width)} px-10 py-4`}
              >
                <PortableText className="flex flex-col" blocks={column._rawBody} />
              </div>
            </>
          ))}
      </div>
      <div className="hidden w-6/12 " />
    </div>
  )
}
