/* eslint-disable react/prop-types */
import React from 'react'

import buildImageObj from '../lib/helpers'
import imageUrlFor from '../lib/image-url'

const Figure = ({ data, width = 600 }) => {
  const imgUrl =
    data &&
    imageUrlFor(buildImageObj(data))
      // .width('100')
      // .height(Math.floor((9 / 16) * width))
      .fit('crop')
      .auto('format')
      .url()

  return imgUrl ? <img src={imgUrl} alt={data.alt || ''} /> : <></>
}

export default Figure
