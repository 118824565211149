/* eslint-disable react/prop-types */
import 'react-multi-carousel/lib/styles.css'

import { Link } from 'gatsby'
import Image from 'gatsby-plugin-sanity-image'
import React from 'react'
import Carousel from 'react-multi-carousel'

const responsive = {
  '2xl': {
    breakpoint: { max: 3000, min: 1536 },
    items: 8,
    slidesToSlide: 1, // optional, default to 1.
  },
  xl: {
    breakpoint: { max: 1536, min: 1280 },
    items: 7,
    slidesToSlide: 1, // optional, default to 1.
  },
  lg: {
    breakpoint: { max: 1280, min: 1024 },
    items: 6,
    slidesToSlide: 1, // optional, default to 1.
  },
  md: {
    breakpoint: { max: 1024, min: 768 },
    items: 5,
    slidesToSlide: 1, // optional, default to 1.
  },
  sm: {
    breakpoint: { max: 768, min: 640 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
}

export default function CarouselComponent({ deviceType, section }) {
  const { carouselPosters } = section
  console.log(carouselPosters)
  return (
    <Carousel
      swipeable
      draggable
      responsive={responsive}
      ssr // means to render carousel on server-side.
      infinite
      autoPlay={false}
      autoPlaySpeed={1000}
      keyBoardControl
      transitionDuration={500}
      containerClass="carousel-container my-20 overflow-visible"
      removeArrowOnDeviceType={['mobile', 'sm']}
      deviceType={deviceType}
      itemClass="shadow-lg  "
      sliderClass="gap-4 sm:gap-6 md:gap-10 lg:gap-14 xl:gap-20 my-20 overflow-visible"
    >
      {carouselPosters.map((item, i) => {
        if (item.figure?.asset) {
          return (
            <Image
              key={i}
              // pass asset, hotspot, and crop fields
              {...item.figure}
              // tell Sanity how large to make the image (does not set an y CSS)
              alt="kuva sivustolla"
              width={260}
              height={400}
              // style it how you want it
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                pointerEvents: 'none',
              }}
            />
          )
        }
        return (
          <Link key={i} to={`${item.internalLink && item.internalLink.href}`}>
            <div className="w-full h-full flex justify-center items-center bg-aquamarine">
              <h3 className="font-unica-one  text-xl md:text-2xl lg:text-3xl text-center text-black tracking-widest">
                {item.title}
              </h3>
            </div>
          </Link>
        )
      })}
    </Carousel>
  )
}

{
  /* <Link to={`/${item.internalLink && item.internalLink.slug.current}`} key={i} className="">
<div className="absolute inset-0 flex justify-center items-center hover:bg-aquamarine shadow-lg transition-all duration-200">
  {item.figure && item.figure.caption && (
    <p className="font-unica-one text-3xl text-center text-black tracking-widest">
      {item.figure.caption}
    </p>
  )}
</div>

{item.figure && (
  <Image
    // pass asset, hotspot, and crop fields
    {...item.figure}
    // tell Sanity how large to make the image (does not set an y CSS)
    alt="kuva sivustolla"
    width={260}
    height={400}
    // style it how you want it
    style={{ pointerEvents: 'none' }}
  />
)}
</Link> */
}

// return (
//   <Link to={`/${item.internalLink && item.internalLink.slug.current}`} key={i} className="">
//     <div className="absolute inset-0 flex justify-center items-center hover:bg-aquamarine shadow-lg transition-all duration-200">
//       {item.title && (
//         <p className="font-unica-one text-3xl text-center text-black tracking-widest">
//           {item.title}
//         </p>
//       )}
//     </div>

//     {item.figure?.asset && (
//       <Image
//         // pass asset, hotspot, and crop fields
//         {...item.figure}
//         // tell Sanity how large to make the image (does not set an y CSS)
//         alt="kuva sivustolla"
//         width={260}
//         height={400}
//         // style it how you want it
//         style={{ pointerEvents: 'none' }}
//       />
//     )}
//   </Link>
// )
