import React from 'react'

export default function ContactForm() {
  return (
    <form
      className="form relative"
      name="Yhteydenottolomake"
      method="POST"
      data-netlify="true"
      action="/kiitos"
    >
      <div className="flex flex-col">
        <input type="hidden" name="form-name" value="Yhteydenottolomake" />
        <input type="hidden" name="subject" value="Yhteydenottopyyntö hiustoimi.fi:sta" />
        <label htmlFor="name" className="font-unica-one font-lg text-black tracking-widest">
          Nimi
          <input
            type="text"
            name="name"
            id="name"
            // value={data.name}
            // onChange={handleChange}
            // placeholder={location.pathname === '/en' ? 'Name' : 'Lähettäjä'}
            className="border border-aquamarine-dark rounded p-2 w-full"
          />
        </label>
      </div>
      <div className="flex flex-col">
        <label htmlFor="email" className="font-unica-one font-lg text-black tracking-widest">
          Sähköposti
          <input
            type="text"
            name="email"
            id="email"
            // value={data.name}
            // onChange={handleChange}
            // placeholder={location.pathname === '/en' ? 'Name' : 'Lähettäjä'}
            className="border border-aquamarine-dark rounded p-2 w-full"
          />
        </label>
      </div>
      <div className="flex flex-col">
        <label htmlFor="tel" className="font-unica-one font-lg text-black tracking-widest">
          Puhelin
          <input
            type="text"
            name="tel"
            id="tel"
            // value={data.name}
            // onChange={handleChange}
            // placeholder={location.pathname === '/en' ? 'Name' : 'Lähettäjä'}
            className="border border-aquamarine-dark rounded p-2 w-full"
          />
        </label>
      </div>
      <div>
        <label htmlFor="message" className="font-unica-one font-lg text-black tracking-widest">
          Viesti
          <textarea
            type="text"
            name="message"
            id="message"
            // value={data.message}
            // onChange={handleChange}
            cols="10"
            rows="3"
            // placeholder={location.pathname === '/en' ? 'Message' : 'Kerro lisää'}
            className=" border border-aquamarine-dark rounded p-2 w-full"
          />
        </label>
      </div>
      <button
        type="submit"
        className="text-aquamarine-dark hover:text-black font-unica-one text-3xl tracking-widest"
      >
        LÄHETÄ
      </button>
    </form>
  )
}
