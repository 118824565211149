import BaseBlockContent from '@sanity/block-content-to-react'
import { Link } from 'gatsby'
import React from 'react'

import clientConfig from '../../client-config'
import ContactForm from './ContactForm'
import Figure from './Figure'

//* Example taken from: https://github.com/sanity-io/example-company-website-gatsby-sanity-combo
const serializers = {
  marks: {
    internalLink: ({ mark, children }) => {
      const { href } = mark
      return (
        <Link className="text-aquamarine-dark hover:text-black cursor-pointer" to={href}>
          {children}
        </Link>
      )
    },
    link: ({ mark, children }) => {
      const { href } = mark
      return (
        <a className="text-aquamarine-dark hover:text-black cursor-pointer" href={href}>
          {children}
        </a>
      )
    },
    // em: ({ children }) => <em>{children}</em>,
    // bold: ({ children }) => <strong>{children}</strong>,
    // yellow: ({ children }) => <span className="text-yellow">{children}</span>,
    center: ({ children }) => <span className="block text-center">{children}</span>,
    left: ({ children }) => <span className="block text-left">{children}</span>,
    right: ({ children }) => <span className="block text-right">{children}</span>,
  },
  listItem: props =>
    props.node.listItem === 'bullet' ? (
      <li className="list-disc font-voltaire text-base text-black tracking-wider">
        {props.children}
      </li>
    ) : (
      <li className="list-decimal font-voltaire text-base text-black tracking-wider">
        {props.children}
      </li>
    ),
  list: props =>
    // console.log("list", props) ||
    props.type === 'bullet' ? (
      <ul className="pl-6 ul">{props.children}</ul>
    ) : (
      <ol className="">{props.children}</ol>
    ),
  types: {
    block(props) {
      switch (props.node.style) {
        case 'h1':
          return (
            <h1 className="font-unica-one text-3xl text-center text-black tracking-widest leading-loose">
              {props.children}
            </h1>
          )
        case 'h2':
          return (
            <h2 className="font-voltaire text-xl text-black tracking-wider leading-relaxed">
              {props.children}
            </h2>
          )
        case 'normal':
          return (
            <p className="font-voltaire text-base text-black tracking-wider">{props.children}</p>
          )
        // case 'h1':
        //   return <h1 className="title">{props.children}</h1>

        // case 'h2':
        //   return <h2 className="heading-two">{props.children}</h2>

        // case 'h3':
        //   return (
        //     <h3 className="heading-three mb-small last:mb-0">
        //       {props.children}
        //     </h3>
        //   )

        // case 'h4':
        //   return (
        //     <h4 className="heading-four mb-smaller last:mb-0">
        //       {props.children}
        //     </h4>
        //   )

        // case 'subtitle':
        //   return (
        //     <p className="subtitle mb-regular last:mb-0">{props.children}</p>
        //   )

        // case 'subtitle-small':
        //   return <p className="subtitle-small">{props.children}</p>

        // case 'body':
        //   return <p className="body mb-6 last:mb-0">{props.children}</p>

        // case 'body-small':
        //   return <p className="body-small mb-6 last:mb-0">{props.children}</p>

        // case 'small':
        //   return <p className="small">{props.children}</p>
        // case 'smaller':
        //   return <p className="smaller">{props.children}</p>

        default:
          return null
      }
    },
    figure: props => <Figure data={props.node} />,
    form: props => <ContactForm data={props} />,
  },
}

export default function BlockContent({ blocks, className }) {
  console.log('blocks', blocks)
  return (
    <BaseBlockContent
      className={`${className}`}
      blocks={blocks}
      serializers={serializers}
      {...clientConfig.sanity}
    />
  )
}
