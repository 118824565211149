import React from 'react'

import Carousel from './Sections/Carousel'
import MultiColumnSection from './Sections/MultiColumnSection'

export default function Content({ content }) {
  return (
    <>
      {content &&
        content.map((section, index) => (
          <>
            {(() => {
              switch (section._type) {
                case 'multiColumnSection':
                  return section && <MultiColumnSection key={index} section={section} />
                case 'carousel':
                  return section && <Carousel key={index} section={section} />
                default:
                  return null
              }
            })()}
          </>
        ))}
    </>
  )
}
